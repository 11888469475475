:root {
    /*Margins & Paddings*/
    --padding: 1rem; 
    --margin: 1rem; 
    --vertical-padding: 1rem; /* must NOT be a percentage value */
    --vertical-margin: 1rem; /* must NOT be a percentage value */
    /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 1100px;
    /*General*/
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
    --map-marker-color: 000;
    /*Colors*/
    --text-color: #303030;
    --body-bg: #EAEAEA;
    --main-color: #eee;
    --accent-color: #39959a;
    --effect-color: #93cfd2;
    --header-text-color: var(--main-color);
    --header-bg-color: rgba(255, 255, 255, 0.8);
    --footer-bg-color: #3D0814;
    --footer-text-color: #fff;
    --menu-text-color: #cb4e60;
    --menu-text-hover-color: #410b13;  
    /*Fonts*/
    --base-font-family: "Quattrocento Sans", "Helvetica", "Arial", sans-serif;
    --alternative-font-family: "Cookie", "Helvetica", "Arial", sans-serif;
}

@import "/fonts/Quattrocento Sans/all.css";
@import "/fonts/Cookie/all.css";

@import "/css/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/css/basic_styling.css";
@import "/css/flex_grid.v1.1.css";
@import "/css/slick.css";
@import "/css/slick-theme.css";
@import "/css/menu.v1.2.css";
@import "/css/magnific-popup.css";
/*@import "/css/pikaday.css";*/
/*@import "/css/animate.css";*/



.header-inner {
    max-width: 90%;
    margin: 0 auto;
}

header .container {
    padding :0 0.5em;
}

.topcontact-outer {
    background: none;
}


.topcontact-outer {
    background: none;
}

.topcontact {
    border-radius: 0 0 5px 5px;
    background: #cb4e60;
    color: #fff;
    padding: 0.5rem 1rem;
    float: right;
    font-size: 1.1rem;
}

.topcontact svg {
    margin-left: 1rem;
    margin-right: 0.5rem;
    vertical-align: text-top !important;
}

.topcontact a {
    color: #fff;
}
.topcontact a:hover {
    color: #fff;
}



.homepage h1 {
    margin-top: 0px;
}


td, th {
    border-bottom: .1em solid #e2e2e2
}




.inner {
    padding: 25px;

}


.startboxinner {
    padding: 10px;
    background: #eee;
    min-height: 400px;
}




.slick-dots {
    position: absolute;
    bottom: -13px;
    display: block;
    width: 100%;
    padding: 0;
    list-style: none;
    text-align: center;
}

.slick-dots li button:before {
    font-family: slick;
    font-size: 20px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: #4f5154;
}

.slick-dots li.slick-active button:before {
    opacity: .75;
    color: #4f5154;
}



/*------------------------------------------------------------------------------------------------ 
GENERAL ----------------------------------------------------------------------------------------*/

body {
    font-family: var(--base-font-family);
    line-height: 1.5;
    font-weight: 300;
    color: var(--text-color);
    background: var(--body-bg);
}
p {
    margin: 3px 0 5px;
}
a, a:hover {
    text-decoration: none;
    color: #303030;
}
img {
    max-width: 100%;
    height: auto;
    flex: none;
    display: block;
}

.imagetop {
    min-height: 58vh;
    background: no-repeat center center;
    background-size: cover;
    position: relative;
}

.imagetop:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 10px;
    background: #d1d1d1;
}

figure {
    margin: 0px;
}
.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

/* ------------------------------- FONTS + HEADINGS + BUTTON ---------------------------- */
h1, h2, h3, h4, h5, h6 {
    font-family: var(--alternative-font-family);
    margin: 0.5em 0 0 0; 
    font-weight: 400;
    color: #3d0814;

}

.homepage h1, .homepage h2,  .homepage h3, .homepage h4, .homepage h5, .homepage h6 {
    color: #fff !important;
}

.box3 h2, .box3 h3 {
    color: #303030 !important;
}
.box1 {
    background: #410B13;
    color: #fff;
}
.box2 {
    background: #cb4e60;
    color: #fff;
}
.box3 {
    background: #EAEAEA;
}
.box3 h5 {
    color: #303030 !important;
}
h1 {
    font-size: 3.4em;

}
h2 {
    font-size: 2em;

}
h3 {
    font-size: 2em;

}
h4 {
    font-size: 1.8em;

}
h5 {
    font-size: 1.5em;

}
h6 {
    font-size: 1em;

}
.slick-title {
    font-size: 2.2rem;
    font-family: var(--alternative-font-family);
    font-weight: 600;
    text-align: center;
    padding-left: 20px;
}
.button {
    margin: 1rem 0px;
    background: #e0e0e0;
    border: none;
    transition: background 1s;
}

.button:hover {
    background: #E3E3E3;
}

/* ------------- WRAPPERS ------------- */
.container.sml {
    max-width: 900px;
}
.container.med {
    max-width: 1300px;
}
.container.lrg {
    max-width: 1500px;
}
.container {
    width: 100%; /*fixar issue med bredd i vissa flexelement i IE*/
}
.wrapper {
    min-height: 100vh;
    position: relative;
    /*margin-top: 80px; För att flytta ner innehåll vid position:fixed på header*/
}

/*------------------------------------------------------------------------------------------------ 
STARTSIDA ----------------------------------------------------------------------------------------*/

/* ---------------SLIDER----------- */
.slick {
    margin-bottom: 0;

}
.slick-track, .slick-substitute {
    display: flex;
    flex-direction: row;

} 
.slick-initialized .slick-slide, .slick-substitute > div {
    height: 100%;
    min-height: 88vh;
    width:100%;
    display: flex;
    background-size: cover;
    background-position: center top;
}

/*------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- 
CONTENT -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

/* ----------------STARTTEXT------------  */
.starttext {
    text-align: center;
}

/* ------------------STARTBOXARNA----------- */
.startbox {
    height: 39vh;
    background-size: cover;
    background-position: center center;
}
a.boxwrapper {
    background-color: rgba(31, 31, 31, 0.26);
    height: 100%;
    transition:background 0.5s ease-in 0s;
}
.startbox:hover a.boxwrapper {
    background: rgba(109, 106, 106, 0.3);  
    transition:background 0.5s ease-in 0s;
}
a.boxwrapper h2 {
    transition:color 0.5s ease-in 0s;
    text-align: center;
    font-size: 3em;
    color: #fff;
}
.startbox:hover a.boxwrapper h2 {
    color:#ffffff;
    transition:color 0.5s ease-in 0s;
}


/*------------------------------------------------------------------------------------------------ 
Nyheter & Nyhet--------------------------------------------------------------------------------*/
/* -----------------NYHETER---------------- */

.artikelbild {
    float: right;
    max-width: 40%;
    height: auto;
    margin: 0 0 15px 15px
}
.newsitem hr {
    color: #efefef;
}

/*------------------------------------------------------------------------------------------------ 
General content--------------------------------------------------------------------------------*/

/* ----------------PAGINAION------------  */

div.pagination {
    margin: 2rem auto 0;
    padding-bottom: 1vh;
    list-style: none;
    width:100%;
    text-align: center;
}

ul.pagination  li {
    display: inline-block;
    padding-right: 5px;
}

ul.pagination  li a {
    background: #000;
    color: white;
    text-decoration: none;
    padding: 4px 8px;
}

.pagination .active a, .pagination a:hover {
    background: #303030;
}


/* -------------- logo gallery ------------ */
.logotype-gallery {
    padding: 2em 0;
}
.logotype-image {
    min-height: 70px;
} 
.logotype-image div, .logotype-image a {
    text-align: center; 
    width: 100%; 
    flex:0 0 auto; 
    display: block;
} 
.logotype-image img {
    height: auto;
} 
.standing  {
    width:40%; 
} 
.squarish  {
    width:40%; 
} 
.landscape  {
    width:55%; 
}
.longlandscape {
    width:70%; 
}
.extralonglandscape {
    width:100%; 
}

/* -------------- mfp-ajax ------------ */

.mfp-ajax-holder .wrapper{
    min-height: auto;
}

.mainAjaxContent :first-child{
    margin-top: 0;
}

.mfp-ajax-holder{
    background: #FFF;
    padding: 20px 30px;
    text-align: left;
    max-width: 650px;
    margin: 40px auto;
    position: relative;
    height: calc(100% - 80px);
    overflow-y: auto;
}

.mfp-close {
    margin: 0;
}
a#bokadirekt {
    color: white !important;
    text-decoration: underline !important; 
}

/*------------------------------------------------------------------------------------------------ 
HEADER -----------------------------------------------------------------------------------------*/

/* ------------NAV + HEADER--------------- */
.logo a {
    font-size: 2em;
    font-weight: 400;
}
header {
    background: var(--header-bg-color);
    z-index: 10;
    width: 100%;
    position: fixed; /*om fixed el absolute, glöm ej margin-top på main*/
    top: 0;
    padding: 10px;
    padding-top: 0px;
    border-top: 5px solid #cb4e60;
}
.logo {
    padding: 10px;
}
.logo img {
    max-height: 200px;
    width: auto;
    position: relative;
}
.logo a, .logo img {
    display: block;
}
header nav {}
header nav ul.menu {}
header nav ul.menu li {}
header nav ul.menu li a {
    color: var(--menu-text-color);
    font-size: 1.1em;
    letter-spacing: 1px;
    font-weight: 300;
    white-space: nowrap;
    border-bottom: 1px solid transparent;

}
.menu.menu li a:hover, .menu.menu li.active a {
    color: var(--menu-text-color);
    border-bottom: 1px solid #cb4e60;

}

/*
nav ul.menu li.dropdown > a:after { content: "<"; display: inline-block; margin: 0 0 0 5px; }
*/

/*----------------------------MOBILMENY----------------------------*/
@media (max-width: var(--breakpoint-menu)) 
{
    header nav ul.menu.show, header nav ul.menu.menu, header nav ul.menu li a,  header nav ul.menu .dropdown ul,  ul.sub-menu-items {
        background: #fff;
    }


}



/*------------------------------------------------------------------------------------------------ 
FOOTER & KONTAKT--------------------------------------------------------------------------------*/
/* -----------------FOOTER---------------- */
footer {
    background-color: var(--footer-bg-color);
    padding: 30px 0;
}
footer, footer a, footer a:hover { 
    color: var(--footer-text-color); 
}
footer svg {
    margin-right: 10px;
    fill: var(--footer-text-color);
}
footer h4 {
    margin-top:0;
    color: #fff;
}

@media (max-width:800px) {
    footer a {
        display:block;
        padding: 1.2rem 0;
    }
}


/* -----------------Intendit-footer---------------- */
.created-by {
    background: var(--footer-bg-color);
    text-align: center;
    padding: 10px 0; 
}
p.intenditfooter, p.intenditfooter a, p.intenditfooter a:hover  {
    color: #651e2e;
    line-height:1.1;
}
p.intenditfooter {
    margin:0;
}
/* -----------------KONTAKT---------------- */


/* -----------------KONTAKTFORMULÄR---------------- */

.boltform form { 
    display: flex; 
    flex-wrap: wrap; 
    flex-direction: row;
}

.boltforms-row {
    flex-basis: calc(100% - 20px);
    padding-right: 10px;
    padding-left: 10px;
    box-sizing: content-box;
}

.boltforms-six-row {
    flex-basis: calc(50% - 20px);
}

.boltforms-four-row {
    flex-basis: calc((100% / 3) - 20px);
}

.boltforms-three-row {
    flex-basis: calc(25% - 20px);
}

.boltform textarea, .boltform input {
    width: 100%;
}

@media (max-width: var(--breakpoint-s))
{
    .boltforms-row {
        flex-basis: auto;
    }

    .boltform form {
        flex-direction: column;
    }
}

.formwrapper {
    background-color: #ececec; 
}

.boltforms-row button {
    margin: 0 auto;
    display: block;
    background: #252525;
    border: none;
}

.boltforms-row button:hover {
    background: #000;
    color: #fff;

}
/* --------------- Google maps --------------------*/
/* Give map a height and it's images no max-width */
.map-canvas {
    height: 35vh;
}

.map-canvas img {
    max-width: none;
}


.fa-map-marker{
    background: url('data:image/svg+xml;utf8,%3Csvg%20fill%3D%22%23var(--map-marker-color)%22%20width%3D%221792%22%20height%3D%221792%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1152%20640q0-106-75-181t-181-75-181%2075-75%20181%2075%20181%20181%2075%20181-75%2075-181zm256%200q0%20109-33%20179l-364%20774q-16%2033-47.5%2052t-67.5%2019-67.5-19-46.5-52l-365-774q-33-70-33-179%200-212%20150-362t362-150%20362%20150%20150%20362z%22%2F%3E%3C%2Fsvg%3E');
    background-size: cover;
    padding: 0px;
    background-position: center;
    font-size: 0;
}

.markerLabels {
    overflow: visible !important;
}


/*------------------------------------------------------------------------------------------------ 
MEDIA QUERIES ----------------------------------------------------------------------------------*/
/*----------------------------Allt under mobilmeny----------------------------*/
@media (max-width: var(--breakpoint-menu)) 
{

}

/*----------------------------Allt under 1500px----------------------------*/
@media (max-width:1500px)
{
    .container.lrg {
        width: 96%; 
    } 

    .formwrapper {
        margin-top: 1vh;
    }
}
/*----------------------------Allt under 1300px----------------------------*/

@media (max-width:1300px)
{
    .container.med {
        width: 96%; 
    } 
}

/*----------------------------Allt under 1100px----------------------------*/
@media (max-width: var(--breakpoint-m))
{

    .container {
        width: 96%; 
    } 

    header {
        border-top: 0px;
    }
}
/*----------------------------Allt under 800px----------------------------*/
@media (max-width: var(--breakpoint-s))
{
    .container.sml {
        width: 96%; 
    } 

    footer .s-twelve {
        text-align: center; 
    }
    footer .s-twelve a {
        text-align:center; 
        margin: 0 auto; 
    } 
    footer img {
        margin: 0 auto;
    }
    .slick-title h2 {
        font-size: 1.5em; 
    }
    table {
        overflow-x: scroll;
        font-size: 0.9em;
    }
    .artikelbild {
        float: none;
        max-width: 100%;
        height: auto;
        margin: 0px
    }

}
/*---------------------------MEDIA XS - upp till 550-----------------------------*/
@media (max-width: var(--breakpoint-xs))
{ 
    .logo img {
        max-height: 32px;
    }
    .slick-title h2 {
        font-size: 1.3em; 
    }
   td {
        border: none;
        display: block;
        width: 100% !important;
        padding: 5px 1em !important;
    }
    tr td:nth-child(1) {width: 100% !important;}
    tr td:nth-child(2) {width: 100% !important; border-bottom: 1px solid #c7c7c7;}

   
}
